.home {
  background: #f6f6f6 url('../images/playstation-pattern.png');

  h1 {
    text-align: center;
  }

  .teaser-copy {
    padding: 3rem;
    background: $white;
    border-radius: 6px;
    margin-bottom: -6rem;
    z-index: 1;
    position: relative;
    box-shadow: 7px 13px 11px rgba(0,0,0,0.1);
  }

  .tagline {
    text-align: center;
    font-weight: 400;
    max-width: 840px;
    margin: 0 auto;
  }
}

.ad-container {
  img {
    margin-top: -73px;
    border-radius: 3px;
  }

  .copy-container {
    position: relative;
    width: 100%;
    height: 280px;
    top: -280px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .copy-container__callout {
    color: $white;
    font-size: 24px;
    line-height: 28px;
    flex: 0 0 100%;
  }

  #player {
    font-weight: bold;
  }

  .copy-container__button {
    background: #5a2525;
    color: $white;
    padding: 1rem 2rem;
    font-weight: bold;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      text-decoration: none;
      background: darken(#5a2525, 7%);
    }
  }
}

.quiz-cta-container {
  display: flex;
  flex: 0 0 100%;
  justify-content: space-between;
  padding: 4rem 1rem;
  margin-top: 5rem;

  .quiz-cta {
    flex: 0 0 30%;
    text-align: center;
    padding: 3rem 1rem;
    height: 280px;
    background-color: $white;
    border-radius: 3px;
    position: relative;
    box-shadow: 1px 1px 13px rgba(0,0,0,0.05);

    &.ad-container {
      padding: 0;
    }
  }

  .quiz-cta__title {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    width: 100%;

    .subtitle {
      font-size: 14px;
      font-weight: 300;
    }
  }

  .quiz-cta__body {
    z-index: 1;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;

    a {
      display: block;
      color: $white;
      background: $brand-warning;
      padding: 1rem 3rem;
      border-radius: 3px;
      font-size: 24px;
      text-transform: uppercase;
      transition: all 0.2s ease;
      font-weight: 700;
      letter-spacing: 2px;

      &:hover,
      &:focus {
        background: lighten($brand-warning, 7%);
        text-decoration: none;
      }
    }
  }

  .quiz-cta__icon {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    text-align: center;
    font-size: 220px;

    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      color: #f6f6f6;
    }
  }

  @media all and (max-width: 1199px) {
    .quiz-cta.ad-container img {
      width: 336px;
      left: 0;
      max-width: none;
      margin-top: -73px;
    }
  }

  @media all and (max-width: 991px) {
    flex-wrap: wrap;

    .quiz-cta {
      flex: 0 0 45%;

      &:first-of-type {
        order: 2;
      }

      &:last-of-type {
        order: 3;
      }

      &.ad-container {
        order: 1;
        flex: 0 0 100%;
        background: transparent;
        box-shadow: none;
        margin-bottom: 4rem;
      }
    }
  }

  @media all and (max-width: 615px) {
    flex-wrap: wrap;

    .quiz-cta {
      flex: 0 0 100%;
      margin-bottom: 2rem;
    }
  }
}
