.header {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: $white;
  border-bottom: 1px solid #eee;

  nav {
    float: right;

    ul {
      margin: 0;

      li {
        display: inline;
        list-style: none;
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;

          a {
            background: $brand-primary;
            color: $white;
            padding: .8rem 1.8rem;
            display: inline-block;
            border-radius: 4px;
            transition: all 0.2s ease;

            &:hover,
            &:focus {
              background: darken($brand-primary, 7%);
              text-decoration: none;
            }
          }
        }

        a {
          font-size: 15px;
          font-weight: normal;
          color: #636363;
        }
      }
    }
  }

  .home__link {
    font-size: 18px;
    font-weight: 400;
    padding: 1rem;
    color: $brand-primary;
    border-radius: 3px;
    transition: all 0.2s ease;

    i {
      margin-right: 10px;
      font-size: 31px;
      vertical-align: middle;
      line-height: 0;
      color: $brand-primary;
      position: relative;
      top: -2px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: darken($brand-primary, 4%);
    }
  }

  @media all and (max-width: 991px) {
    text-align: center;

    .home__link {
      margin: 0 auto;
      display: inline-block;
      margin-bottom: 1rem;
    }

    nav {
      float: none;
      text-align: center;

      ul {
        padding: 0;

        li {
          display: block;
          margin: 0;
        }
      }
    }
  }
}