html, body {
  background-color: #fff;
  color: #636b6f;
  font-weight: 400;
  height: 100vh;
  margin: 0;
}

body {
  min-height: 100%;
  height: auto;
  position: relative;
}

img {
  max-width: 100%;
}

.content-wrapper {
  padding-bottom: 100px;
  line-height: 24px;

  p {
    margin-bottom: 30px;
    font-size: 15px;
  }

  .above-content-ad-space,
  .below-content-ad-space {
    .mobile {
      display: none;
    }

    .full-width {
      display: block;
    }

    @media all and (max-width: 800px) {
      .mobile {
        display: block;
      }

      .full-width {
        display: none;
      }
    }
  }
}

.inner-container {
  max-width: 1170px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.subnav {
  margin-top: 22px;

  .menu {
    padding: 1rem;
    margin: 0;
    background: transparent;
    border-radius: 4px;

    li {
      list-style: none;
      padding: .7rem 0;
      border-bottom: 1px solid #e6e6e6;

      &.active a {
        font-weight: 500;
      }

      a {
        color: $brand-primary;
      }
    }
  }
}

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 12px;
  padding-top: 3rem;
  padding-bottom: 15px;
  background: #e6e6e6;

  h2 {
    font-size: 16px;
    font-weight: 900;
  }
}

.contact-form-wrapper {
  .errors {
    padding: 0;
    list-style: none;

    li {
      padding: 5px 10px;
      margin-bottom: 10px;
      color: #bf5329;
      background: rgba(191, 83, 41, 0.1);
      border: 1px solid;
      border-radius: 4px;
    }
  }
}

.error-page {
  text-align: center;

  .container {
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    width: 100%;
  }

  h1 {
    font-size: 156px;
    color: #e8e8e8;
    margin-top: 0;
  }

  h3 {
    margin-top: 0;
  }
}

.fbiq-callout {
  background: url('../images/blurred-players.jpg') center top;
  background-size: cover;
  padding: 5rem 2rem;
  margin-bottom: 4rem;
  text-align: center;
  position: relative;
  box-shadow: 2px 6px 16px rgba(0,0,0,0.2);
  border-radius: 4px;

  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.2);
  }

  p {
    position: relative;
    color: $white;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
  }

  a {
    position: relative;
    display: inline-block;
    padding: 2rem 5rem;
    background: $brand-primary;
    color: $white;
    text-decoration: none;
    transition: all 0.2s ease;
    border-radius: 4px;

    &:hover,
    &:focus {
      background: darken($brand-primary, 7%);
    }
  }
}

.fbiq-teasers {
  background: url('../images/stats-bg.jpg') center center no-repeat;
  padding: 14rem 0 8rem;
  color: $white;

  h2 {
    margin-top: 0;
  }
}

.fbiq-teasers__button {
  background: $brand-warning;
  color: #333;
  padding: 1.5rem 4rem;
  text-decoration: none;
  border-radius: 3px;
  font-weight: bold;
  display: inline-block;

  &:hover,
  &:focus {
    text-decoration: none;
    background: lighten($brand-warning, 7%);
    color: #333;
  }
}

.fbiq-header-callout {
  text-align: center;
  margin-top: 2rem;
  background: $brand-primary;
  padding: 2rem 8rem;
  color: $white;
  position: relative;
  font-size: 16px;
  background: linear-gradient(to bottom right,#3aacec,#0498ec);
  border-radius: 6px;

  &::before,
  &::after {
    background: url("../images/football-helmet.svg") top left no-repeat;
    background-size: 24px;
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  }

  &::before {
    transform: scale(-1, 1) translateY(-50%);
  }

  &::after {
    right: 40px;
    left: initial;
  }

  p {
    margin: 0;
    font-size: 16px;
  }

  a {
    background: $white;
    padding: 1rem;
    border-radius: 4px;
    margin-left: 2rem;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 2px 3px 15px rgba(0,0,0,0.2);
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      text-decoration: none;
      box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    }
  }
}

.above-content-ad-space,
.below-content-ad-space {
  margin-top: 2rem;
  margin-bottom: 2rem;
  overflow: hidden;
}

.quiz-ad-top,
.quiz-ad-bottom {
  overflow: hidden;
}

.checkout-page {
  background: url('/images/playstation-pattern.png');

  .alert {
    margin-top: 2rem;
    text-align: center;

    p {
      margin-bottom: 0;
    }
  }

  .content {
    background: $white;
    padding: 4rem;
    border: 1px solid #eee;
    overflow: hidden;
    margin-bottom: 2rem;
  }

  .product-info {
    background: $white;
    padding: 2rem 4rem;
    border: 1px solid #eee;
    margin-bottom: 4rem;

    p {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      font-weight: bold;

      .price {
        font-weight: normal;
      }
    }
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
    max-width: 500px;

    label {
      margin-bottom: -1px;
      display: flex;
      align-items: center;
      border: 1px solid #eeeeee;
      padding: 7px 0 7px 20px ;
      background: #fff;
      //border-radius: 3px;
      //box-shadow: 5px 5px 13px rgba(0, 0, 0, 0.05);

      span {
        flex: 0 0 100px;
      }

      input {
        width: 100%;
        border: 0;
        padding-right: 20px;
        font-weight: normal;

        &::-webkit-input-placeholder {
          color: #aab7c4;
          font-weight: normal;
        }
        &::-moz-placeholder {
          color: #aab7c4;
          font-weight: normal;
        }
        &:-ms-input-placeholder {
          color: #aab7c4;
          font-weight: normal;
        }
      }

      select {
        width: 100%;
        flex: 1;
        border-style: none;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: #313b3f;
        cursor: pointer;
        background: transparent;
      }
    }
  }

  .stripe-card,
  .country {
    width: 100%;
  }

  .StripeElement {
    flex: 1;
  }

  .payment-info {
    margin-bottom: 2rem;
  }

  .pay-with-stripe {
    display: block;
    margin-top: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: $brand-primary;
    color: $white;
    padding: 12px 20px;
    border: 0;
    font-family: $headings-font-family;
    font-weight: normal;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover,
    &:focus {
      background: darken($brand-primary, 7%);
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;

      &:hover,
      &:focus {
        background: $brand-primary;
      }
    }
  }
}

.buy-wrapper {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 70%;
  margin: 0 auto;

  p {
    margin-bottom: 0;
  }

  .buy-button {
    background: $brand-primary;
    padding: 1rem 3rem;
    display: inline-block;
    color: $white;
    border-radius: 2px;
    box-shadow: 2px 2px 9px transparentize($brand-primary, .7);
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      text-decoration: none;
      background: darken($brand-primary, 7%);
      box-shadow: 1px 1px 4px transparentize($brand-primary, .7);
    }
  }
}

@media all and (max-width: 991px) {
  .content-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .fbiq-header-callout {
    margin-left: 15px;
    margin-right: 15px;

    a {
      display: block;
      margin-top: 2rem;
    }
  }
}